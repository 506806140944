/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faEnvelope from "@fortawesome/fontawesome-free-solid/faEnvelope";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Input } from "reactstrap";
import BaseField, { fieldPropTypes } from "./BaseField";
type Props = {
    fieldName: string;
    label: string;
    placeHolder?: string;
    validation?;
    helpText?: string;
};
const EmailField = ({
    fieldName,
    label,
    placeHolder,
    validation,
    helpText,
    ...props
}: Props) => (
    <BaseField
        {...props}
        fieldName={fieldName}
        label={label}
        validation={validation}
        helpText={helpText}
        render={(isValid) => (
            <Input
                // @ts-ignore POSSIBLE ERROR FOUND "username" is not an option.
                type="username"
                valid={isValid}
                invalid={isValid === false ? true : null}
                name={fieldName}
                id={fieldName}
                placeholder={placeHolder}
                {...props}
            />
        )}
        icon={
            <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faEnvelope as IconProp} />
            </span>
        }
    />
);

EmailField.propTypes = fieldPropTypes;

export default EmailField;
