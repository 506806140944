/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";
import { USER_FRAGMENT } from "../query/UserQuery";

export const VERY_EMAIL_MUTATION = gql`
    mutation verifyEmail($verification_key: String!) {
        verifyEmail(verification_key: $verification_key) {
            id
            email_address
        }
    }
`;

export const SIGN_UP_MUTATION = gql`
    mutation signUp($input: RegisterInput!) {
        register(input: $input) {
            ...generalUserFields
        }
    }
    ${USER_FRAGMENT}
`;

export const SIGN_IN_MUTATION = gql`
    mutation signIn($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            token
            refreshToken
            wpToken
            user {
                ...generalUserFields
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const SIGN_IN_AS_USER_MUTATION = gql`
    mutation signInAsUser($username: String!, $password: String!, $id: UUID!) {
        signInAsUser(username: $username, password: $password, id: $id) {
            token
            refreshToken
            wpToken
            user {
                ...generalUserFields
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($email_address: Email!) {
        resetPassword(email_address: $email_address)
    }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword(
        $verification_key: String!
        $input: ChangePasswordInput!
    ) {
        changePassword(verification_key: $verification_key, input: $input)
    }
`;

export const UPDATE_IMPERSONATE_STATUS = gql`
    mutation updateImpersonateStatus($impersonate: Boolean!) {
        updateImpersonateStatus(impersonate: $impersonate) @client
    }
`;
