/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";

const FieldGroup = ({ children, isExpanded }) => (
    <div className="field is-grouped">
        <div className={"control" + (isExpanded ? " is-expanded" : "")}>
            {children}
        </div>
    </div>
);

FieldGroup.propTypes = {
    children: PropTypes.any.isRequired,
    isExpanded: PropTypes.bool
};

export default FieldGroup;
