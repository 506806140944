/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import Head from "next/head";
import React, { PropsWithChildren, ReactNode } from "react";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";
// Layout
import Page from "./Page";

type Props = {
    title?: string;
    pageTitle: string;
    includePageTitleTag?: boolean;
    description?: string;
    className: string;
    currentRouteKey?: string;
};

const FluidPage = ({
    title,
    pageTitle,
    includePageTitleTag,
    description,
    children,
    className,
    currentRouteKey
}: PropsWithChildren<Props>) => {
    if (typeof pageTitle === "undefined") {
        pageTitle = title;
    }

    if (typeof includePageTitleTag === "undefined") {
        includePageTitleTag = true;
    }

    let finalPageTitle =
        pageTitle + (includePageTitleTag === true ? " | Convergence" : "");

    return (
        <Page>
            <Head>
                {finalPageTitle && (
                    <>
                        <title>{finalPageTitle}</title>
                        <meta name="og:title" content={finalPageTitle} />
                    </>
                )}
                {description && (
                    <meta name="description" content={description} />
                )}
            </Head>

            <Header currentRouteKey={currentRouteKey} />

            <div id={"content"}>
                <style jsx global>
                    {`
                        #content {
                            margin-top: -90px;
                        }
                        #content > div:first-child {
                            padding-top: 90px;
                        }
                    `}
                </style>

                {title && (
                    <div className="page-title">
                        <Container>
                            <h1>{title}</h1>
                        </Container>
                    </div>
                )}

                <Container fluid className={className}>
                    {children}
                </Container>
            </div>

            <Footer
                // @ts-ignore POSSIBLE ERROR FOUND: className does not exist
                className="bottom"
            />
        </Page>
    );
};

export default FluidPage;
