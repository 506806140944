/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faKey from "@fortawesome/fontawesome-free-solid/faKey";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Input } from "reactstrap";
import BaseField, { fieldPropTypes } from "./BaseField";
type Props = {
    fieldName;
    label;
    placeHolder;
    validation;
    helpText;
    autoComplete?;
    onChange?;
    value: string;
};
const PasswordField = ({
    fieldName,
    label,
    placeHolder,
    validation,
    helpText,
    ...props
}: Props) => (
    <BaseField
        {...props}
        fieldName={fieldName}
        label={label}
        validation={validation}
        helpText={helpText}
        render={(isValid) => (
            <Input
                type="password"
                valid={isValid}
                invalid={isValid === false ? true : null}
                name={fieldName}
                id={fieldName}
                placeholder={placeHolder}
                {...props}
            />
        )}
        icon={
            <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faKey as IconProp} />
            </span>
        }
    />
);

PasswordField.propTypes = fieldPropTypes;

export default PasswordField;
