/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import ApolloClient from "apollo-client";
import React from "react";
import { Mutation } from "react-apollo";
import Card from "../components/layout/Card/Card";
import CardContent from "../components/layout/Card/CardContent";
import FluidPage from "../components/layout/FluidPage";
import LoginContainer from "../components/page/login/LoginContainer";
// Layout
import Link from "../components/theme/3.0/Link";
// Global styles
import { SIGN_IN_MUTATION } from "../graphql/mutation/AuthMutation";
import { authenticateUser } from "../utils/AuthenticationUtility";
// Utils
import redirect from "../utils/Redirect";
import { withApollo } from "../utils/WithApollo";

/**
 * Login Page
 */
const Login = ({ currentRouteKey }) => (
    <FluidPage
        pageTitle="Login"
        className="login-background"
        description="Login to the Convergence platform to access member only products and services"
        currentRouteKey={currentRouteKey}
    >
        <div id="login-container" className="is-centered">
            {/* TS FOUND ERROR and TS CODE CHANGE no prop className */}
            <Card /* className="login-links card" */>
                <CardContent>
                    <Mutation mutation={SIGN_IN_MUTATION}>
                        {(signIn, { client }: any) => (
                            <LoginContainer signIn={signIn} client={client} />
                        )}
                    </Mutation>
                </CardContent>
            </Card>
            <div className="login-links">
                <p>
                    <Link
                        routeKey={"reset-password"}
                        label={"Forgot your password?"}
                    />
                </p>
                <p>
                    Don&#39;t have an account?{" "}
                    <Link routeKey={"sign-up"} label={"Register"} />
                </p>
            </div>
        </div>
    </FluidPage>
);

Login.getInitialProps = async (ctx) => {
    const { user } = await authenticateUser(ctx.apolloClient, ctx);
    if (user) {
        redirect(ctx, "/settings/profile");
    }

    return {
        currentRouteKey: ctx.query.routeKey
    };
};

export default withApollo(Login);
