/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import EmailField from "../../layout/Form/EmailField";
import UsernameField from "../../layout/Form/UsernameField";
import FieldGroup from "../../layout/Form/FieldGroup";
import Form from "../../layout/Form/Form";
import PasswordField from "../../layout/Form/PasswordField";
import SubmitButton from "../../layout/Form/SubmitButton";
type Props = {
    onChange?;
    onSubmitSignIn;
    loginButtonLabel: string;
    loginButtonDisabled: boolean;
    userName?: boolean; // label
    username?: string; // fieldName
    password?: string;
};
let LoginForm: React.FC<Props> = ({
    onChange,
    onSubmitSignIn,
    loginButtonLabel,
    loginButtonDisabled,
    userName, // label
    username, // fieldName
    password
}) => (
    <Form onSubmit={onSubmitSignIn}>
        {!userName && (
            <EmailField
                fieldName="username"
                label="Email Address"
                autoComplete="username"
                onChange={onChange}
                value={username}
            />
        )}
        {userName && <UsernameField fieldName="username" label="Username" />}
        <PasswordField
            fieldName="password"
            label="Password"
            autoComplete="current-password"
            onChange={onChange}
            value={password}
        />
        <FieldGroup isExpanded>
            <SubmitButton
                fieldName="submit"
                label={loginButtonLabel}
                disabled={loginButtonDisabled}
                block
            />
        </FieldGroup>
    </Form>
);

LoginForm = React.memo(LoginForm);

export default LoginForm;
