import React from "react";
import MessageBox from "../../layout/MessageBox";
import LoginForm from "./LoginForm";
import { createCookie } from "../../../utils/AuthenticationUtility";
import {
    getParameterByName,
    getClientHostname
} from "../../../utils/CommonUtils";
import redirect from "../../../utils/Redirect";
import { globalWSClient } from "../../../utils/WithApollo";
import { WithApolloClient } from "react-apollo";
import posthog from "posthog-js";

type Props = {
    signIn;
};

type State = {
    username: string;
    password: string;
    loginButtonLabel: string;
    loginButtonDisabled: boolean;
    [eventTargetName: string]: any;
    error?;
};
class LoginContainer extends React.Component<WithApolloClient<Props>, State> {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loginButtonLabel: "Login",
            loginButtonDisabled: false,
            error: null
        };
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    /**
     * Handler for log in submission
     * @param event
     */
    onSubmitSignIn = (event) => {
        const { signIn, client } = this.props;
        const redirectPath = getParameterByName("redirect");

        event.preventDefault();
        event.stopPropagation();

        this.setState({
            loginButtonDisabled: true,
            loginButtonLabel: "Logging In..."
        });

        signIn({
            variables: {
                username: this.state.username,
                password: this.state.password
            }
        })
            .then(
                ({
                    data: {
                        login: { token, refreshToken, user }
                    }
                }) => {
                    // Store the token in a cookie and our store
                    createCookie({ token, refreshToken });
                    posthog.identify(user.id, {
                        institution_id: user.institution_id
                    });
                    // Force a reload of all the current queries now that the user is
                    // logged in
                    client.resetStore().then(() => {
                        // Now redirect to the homepage
                        if (globalWSClient) {
                            globalWSClient.close();
                            globalWSClient.connect();
                        }

                        if (redirectPath) {
                            redirect({}, getClientHostname() + redirectPath);
                        } else {
                            redirect(
                                {},
                                `/institution/${user.institution_id}/view`
                            );
                        }
                    });
                }
            )
            .catch((error) => {
                // Something went wrong, such as incorrect password, or no network
                // available, etc.
                if (error.message.includes("GraphQL error")) {
                    this.setState({
                        error: error.message.replace(/GraphQL error:/, "")
                    });
                } else {
                    console.error(error);
                    this.setState({
                        error:
                            "An unknown error has occurred. Please try again."
                    });
                }

                this.setState({
                    loginButtonDisabled: false,
                    loginButtonLabel: "Login"
                });
            });
    };

    render() {
        const { loginButtonLabel, loginButtonDisabled } = this.state;

        return (
            <React.Fragment>
                {this.state.error && (
                    <MessageBox type="danger">{this.state.error}</MessageBox>
                )}
                <LoginForm
                    onChange={this.onChange}
                    onSubmitSignIn={this.onSubmitSignIn}
                    loginButtonLabel={loginButtonLabel}
                    loginButtonDisabled={loginButtonDisabled}
                    username={this.state.username}
                    password={this.state.password}
                />
            </React.Fragment>
        );
    }
}

export default LoginContainer;
